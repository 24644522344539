<template>
  <div>
    <tab-bar-wrapper @newRow="createWO"></tab-bar-wrapper>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :contextMenuItems="contextMenuItems"
        :componentName="$options.name"
        :query="query"
        :filter="filter"
        :annotations="annotations"
        :nested-query="nestedQuery"
        :nested-filters="nestedFilters"
        :nested-annotations="nestedAnnotations"
        :is-row-master-function="rowMasterFunction"
        :columns="columns"
        :nested-grid="true"
        :nestedColumns="nestedColumns"
        :nestedContextMenuItems="nestedContextMenuItems"
        @ready="ready"
        @nestedReady="nestedReady"
        @setComments="setComments"
        @setMasterObject="setCurrentOrderObject"
        @cell-key-down="gridKbEventSender"
      />
    </div>
    <action-bar-wrapper
      :commentList="commentList"
      @saveComments="saveComments"
    />
    <v-dialog
      v-model="dialog.show"
      lazy
      persistent
    >
      <v-card v-if="dialog.app === 'addParts'">
        <v-card-title class="brown darken-4">
          <h3 class="white--text">Add supplies to your work order</h3>
        </v-card-title>
        <v-card-text>
          <v-container px-0>
            <add-parts-set :parts="parts" order="wo"/>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-layout row justify-start align-center>
            <t-alert :message="dialogMessage" :type="dialogMessageType"></t-alert>
            <v-spacer></v-spacer>
            <v-btn
              @click="resetDialog"
              :color="addedPartsSuccessfully ? 'primary' : 'error'"
            >
              {{ addedPartsSuccessfully ? 'Close' : 'Cancel' }}
            </v-btn>
            <v-btn
              @click="addPartsToWorkOrder"
              :disabled="parts.length === 0 || addedPartsSuccessfully"
              :loading="isAddingParts"
              color="success"
            >
              Submit
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
      <pdf-viewer :data="pdfData" :custom="pdfRules" @close="resetDialog"/>
    </v-dialog>
  </div>
</template>

<script>
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import grid from '../../components/mixins/grid'
import columns from '../../components/mixins/columns'
import { COLUMNS__WORK_ORDERS, COLUMNS__WORK_ORDERS__NESTED } from '@/lib/agGridColumnConfiguration'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { CONTEXT_MENU__WORK_ORDERS, CONTEXT_MENU__WORK_ORDERS_NESTED } from '@/lib/agGridContextMenuConfigurations'
import { KB_SHORTCUT__WORK_ORDERS, KB_SHORTCUT__WORK_ORDERS_NESTED } from '@/lib/agGridKbShortCuts'
import {
  CREATE_WORK_ORDER_ITEM, GRID_GET_WORK_ORDER_FOR_PDF,
  GRID_GET_WORK_ORDER_ITEMS,
  GRID_GET_WORK_ORDERS
} from '@/api/graphql/Constants/WorkOrders'
import { RowMasterFunctionForItemCountAnnotation } from '@/lib/helpers'
import addPartsTemplate from '@/components/templates/addPartsTemplate'
import tAlert from '@/components/notifications/tAlert'
import { GET_SALE_FOR_PDF } from '@/api/graphql/Constants/Orders'
import { prepPdfData } from '@/lib/PdfPrep'
import ViewPDF from '@/components/dialogs/ViewPDF'
export default {
  name: 'workOrders',
  mixins: [grid, columns],
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper,
    'add-parts-set': addPartsTemplate,
    't-alert': tAlert,
    'pdf-viewer': ViewPDF
  },
  data () {
    return {
      dialog: { show: false, app: '' },
      parts: [],
      dialogMessage: '',
      dialogMessageType: '',
      addedPartsSuccessfully: false,
      isAddingParts: false,
      lineNumber: 0,

      contextMenuItems: params => CONTEXT_MENU__WORK_ORDERS(params, this.contextMenuCallback),
      nestedContextMenuItems: (params) => CONTEXT_MENU__WORK_ORDERS_NESTED(params),

      commentList: {
        'internal_comment': {
          name: 'internal_comment',
          label: 'Internal',
          text: '',
          editable: true,
          show: true
        },
        'system_logs': {
          name: 'system_logs',
          label: 'System',
          text: '',
          editable: false,
          show: true
        }
      },

      query: GRID_GET_WORK_ORDERS,
      filter: [],
      annotations: [
        {
          name: 'itemCount',
          aggr_type: 'COUNT',
          aggr_field: 'wo_items'
        }
      ],

      nestedQuery: GRID_GET_WORK_ORDER_ITEMS,
      nestedFilters: id => [{ key: 'work_order__id', value: id }],
      nestedAnnotations: [],

      columns: COLUMNS__WORK_ORDERS,
      nestedColumns: COLUMNS__WORK_ORDERS__NESTED,

      selectedWorkOrder: {},
      pdfData: {},
      pdfRules: []
    }
  },
  methods: {
    resetDialog () {
      this.dialog.show = false
      this.dialog.app = ''
      this.dialogMessage = ''
      this.addedPartsSuccessfully = false
      this.lineNumber = 0
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__WORK_ORDERS(params, this.contextMenuCallback), KB_SHORTCUT__WORK_ORDERS_NESTED())
    },

    contextMenuCallback (params) {
      if (params.data && params.dataKey) this[params.dataKey] = params.data
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    prepAddProductDialog (workOrder) {
      this.selectedWorkOrder = workOrder
      this.$apollo.query({
        query: GRID_GET_WORK_ORDER_ITEMS,
        variables: {
          input: {
            filters: [{ key: 'work_order__id', value: workOrder.id }]
          }
        }
      }).then(response => {
        this.lineNumber = response.data.items.length + 1
        this.dialog.app = 'addParts'
        this.dialog.show = true
      })
    },

    rowMasterFunction (data) {
      return RowMasterFunctionForItemCountAnnotation(data.annotations)
    },

    createWO () {
      this.$store.dispatch('grid/changeSheet', { app: 'topbar', component: 'newWorkOrder' })
    },

    async viewContract (id) {
      this.$store.dispatch('notifications/createSnackbar', {
        message: 'Grabbing data...',
        color: 'info'
      })
      try {
        const response = await this.$apollo.query({
          query: GRID_GET_WORK_ORDER_FOR_PDF,
          variables: { id: id }
        })
        if (response.data.work_order_work_order) {
          const prepped = prepPdfData.WorkOrder(response.data.work_order_work_order)
          this.pdfData = prepped.pdf
          this.pdfRules = prepped.rules
          this.pdfType = 'sts'
          this.width = '90%'
          await this.$store.dispatch('notifications/hideSnackbar')
          this.dialog.app = 'view-pdf'
          this.dialog.show = true
        }
      } catch (error) {
        let message = ''
        message = error.message ? error.message : error
        this.$store.dispatch('notifications/createSnackbar', {
          message: message,
          color: 'error'
        })
      }
    },

    addPartsToWorkOrder () {
      const supplies = []
      for (const product of this.parts) {
        supplies.push({ work_order_id: this.selectedWorkOrder.id, item_id: product.id })
      }
      this.$apollo.mutate({
        mutation: CREATE_WORK_ORDER_ITEM,
        variables: { input: supplies }
      })
    }
  }
}
</script>
